<template>
    <div class="vendorSign desktop">
        <Header :type="headerType"/> 
        <VendorDataForm :editType="editType"/>
    </div>
</template>
<script>
import Header from '@/components/Header'
import VendorDataForm from '@/components/VendorDataForm'
export default {
    name:'AdminAdd',
    data(){
        return {
            headerType:'empty',
            editType:'adminSignup'
        }
    },
    components:{
        Header,VendorDataForm
    },
    computed:{

    }
}
</script>
<style lang="scss" scoped> 
    @import '@/assets/scss/_variables.scss';
    .vendorSign{
        padding-bottom:88px;
    }
</style>